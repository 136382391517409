<template>
  <form
    class="bg-grey p-4 rounded my-2 lg:flex transition duration-500"
    :class="{ 'is-empty': isEmpty }"
  >
    <div class="lg:w-4/6"><slot></slot></div>
    <div class="flex justify-around lg:hidden my-2 text-xs opacity-50">
      <div>1</div>
      <div>2</div>
      <div>3</div>
      <div>4</div>
    </div>

    <div class="flex justify-around lg:w-2/6 items-center">
      <!-- radio input -->
      <input
        type="radio"
        name="answer"
        id="answer1"
        class="w-6 h-6 border-0 align-middle"
        value="4"
        v-model="theValue"
      />
      <input
        type="radio"
        name="answer"
        id="answer2"
        class="w-6 h-6 border-0 align-middle"
        value="3"
        v-model="theValue"
      />
      <input
        type="radio"
        name="answer"
        id="answer3"
        class="w-6 h-6 border-0 align-middle"
        value="2"
        v-model="theValue"
      />
      <input
        type="radio"
        name="answer"
        id="answer4"
        class="w-6 h-6 border-0 align-middle"
        value="1"
        v-model="theValue"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AnswerComponent",
  // data() {
  //   return {
  //     theValue: this.theValue,
  //     isEmpty: false,
  //     answer_test: 1,
  //   };
  // },
  data() {
    return {
      theValue: this.theValue,
      isEmpty: false,
      // checked: true,
      id: {
        type: Number,
        required: true,
      },
    };
  },
  props: {
    // checked: {
    //   type: Boolean,
    //   required: true,
    // },
    option: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    valueObject: {
      type: Object,
      required: true,
    },
  },
  watch: {
    theValue(newValue) {
      this.$emit("values", newValue, this.option);
    },
    valueObject: {
      handler(newValue) {
        let keys = Object.keys(newValue);
        if (!keys.includes(this.option)) {
          this.isEmpty = true;
          // this.$emit("empty", this.isEmpty);
        } else {
          this.isEmpty = false;
          // this.$emit("empty", this.isEmpty);
        }
      },
      deep: true,
    },
  },

  computed: mapGetters("selection", ["getQuizOneSelections"]),

  methods: {
    //function to check the previous selection of users
    checkValues() {
      for (const [key, value] of Object.entries(this.getQuizOneSelections)) {
        if (this.number == key) {
          this.theValue = value[this.option];
        }
      }
    },
  },
  mounted() {
    this.checkValues();
    console.log(this.getQuizOneSelections);
  },
};
</script>

<style scoped>
.is-empty {
  background: rgb(241, 158, 158);
}
</style>