<template>
  <div class="quiz-page bg-slate">
    <the-navigation></the-navigation>

    <the-popup v-if="popupactive" @close="closepopup">
      <template #title>{{ alert.title }}</template>
      <template #message>{{ alert.message }}</template>
    </the-popup>

    <div class="sticky top-0 z-10 bg-slate">
      <div class="max-w-screen-xl container py-4 m-auto">
        <div class="flex justify-between">
          <div class="remaining-ques">{{ que_num }}/25 completed</div>
          <div class="time font-semibold">{{ formattedTimeLeft }} mins</div>
        </div>
        <div class="progress w-full bg-grey rounded mt-4">
          <div
            class="bg-secondary rounded w-0 h-3 lg:h-6 transition-all"
            id="q1"
          ></div>
        </div>
      </div>
    </div>

    <div class="max-w-screen-xl container py-10 m-auto">
      <div class="instruction my-10">
        <the-instructions class="opacity-80">
          <p>You Need To Rank Each Statement Where</p>
          <p>1 - Always True of Me</p>
          <p>2 - Mostly True of Me</p>
          <p>3 - Sometimes True of Me</p>
          <p>4 - Rarely True of Me</p>
          <p class="font-semibold mt-2">
            In case you think a particular statement is not True Of You at all,
            Please rank it as 4
          </p>
        </the-instructions>
      </div>

      <div class="questions my-24">
        <div class="" v-for="question in questions" :key="question.id">
          <QuestionComponent
            :id="'question' + question.order"
            :label="question.label"
            :number="question.order"
            :answers="question.answer_set"
            @userSelection="compileAnswers"
          />
        </div>
      </div>

      <div class="flex justify-end">
        <a @click="submitAnswers" class="btn">Proceed to section B</a>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionComponent from "../components/quizes/quiz1/QuestionComponent.vue";
import TheInstructions from "../components/quizes/TheInstructions.vue";
import TheNavigation from "../components/TheNavigation.vue";
import quiz from "../utils/database.json";
import ThePopup from "../components/ThePopup.vue";

// 2 hours 1 min 30 sec
const TIME_LIMIT = 86400;

export default {
  components: { TheNavigation, TheInstructions, QuestionComponent, ThePopup },
  name: "QuizPageOne",
  data() {
    return {
      quiz: {},
      questions: quiz.question_set,
      timerInterval: null,
      timePassed: 0,
      answers: {},
      successful: false,
      popupactive: false,
      scrollto: null,
      que_num: 0,
      Json: "",
      quiz_data: {
        quiz_id: 1,
        quiz_type: "mcq_1",
        quiztaker_id: this.$store.state.user.quizTaker.id,
        users_answers: [],
      },
      alert: {
        title: "hello",
        message: "this is the message",
      },
    };
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        alert("Time Up");
      }
    },

    //progress bar
    answers: {
      handler(newValue) {
        let keys = Object.keys(newValue);
        let progress = 0;
        // alert("sup");

        //Checks if the question has been answered properly
        keys.forEach((value) => {
          if (
            newValue[value].hasEmptyValue == false &&
            newValue[value].hasSimilarSel == false
          ) {
            progress = progress + 1;
          }
        });

        //increments progress bar
        let elem = document.getElementById("q1");
        this.que_num = progress;
        progress == 25
          ? (elem.style.width = "100%")
          : (elem.style.width = progress * 4 + "%");
      },
      deep: true,
    },
  },
  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      // hours mintues seconds
      let hours = Math.floor(timeLeft / 3600);
      let minutes = Math.floor((timeLeft - hours * 3600) / 60);
      let seconds = timeLeft - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = `0${hours}`;
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
      return this.timeLeft / TIME_LIMIT;
    },
  },

  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timePassed = this.timePassed += 1;
      }, 1000);
    },

    //  read json data from db.json file in utils folder
    readJsonFile() {
      this.quiz = quiz;
    },

    closepopup() {
      this.popupactive = false;

      // this scrolls to uncompleted answer
      this.$router.push({ name: "QuizOne", hash: `#question${this.scrollto}` });
    },

    //function to compile answers
    compileAnswers(answers, number) {
      this.answers[`${number}`] = answers;
      // save to vue store
      this.$store.commit("SET_QUIZ_ANSWERS", this.answers);
    },

    sendAnswers() {
      const saved_answers = this.$store.state.quiz.users_answers;

      // get the keys of the object
      let keys = Object.keys(saved_answers);
      for (let i = 0; i < keys.length; i++) {
        let question_id = saved_answers[keys[i]].Question;
        let answers = [];
        let answer_keys = Object.keys(saved_answers[keys[i]].Selection);
        for (let j = 0; j < answer_keys.length; j++) {
          const score = saved_answers[keys[i]].Selection[answer_keys[j]];

          const answer = {
            option: answer_keys[j],
            score: parseInt(score),
          };
          answers.push(answer);
        }
        this.quiz_data.users_answers.push({
          question_id: question_id,
          answers: answers,
        });
      }
      // submit answers to the server
      this.$store.dispatch("submitAnswers", this.quiz_data);
    },

    submitAnswers() {
      this.scroll = false;
      if (Object.keys(this.answers).length == 25) {
        this.validateAnswers(); //if validation is successful,

        if (this.successful) {
          this.sendAnswers();
          // this.Json = JSON.stringify(this.answers);
          this.$store.commit("selection/saveQuizOneSelection", this.answers); //This keeps user's selections in the store
          this.$router.push({
            name: "QuizTwo",
          });
        }
      } else {
        this.alert.message = "Answer all questions";
        this.alert.title = "Info";
        this.popupactive = true;
        this.findUnanswered();
      }
    },

    //function to find the first unanswered question
    findUnanswered() {
      let unanswered = Object.keys(this.answers);
      console.log(unanswered);

      for (let i = 1; i <= 25; i++) {
        if (!unanswered.includes(`${i}`)) {
          var num = i;
          break;
        }
      }
      this.scrollto = num;
      console.log(this.scrollto);
      // this.$router.push({ name: "QuizOne", hash: `#question${num}` });
    },

    //function to check if questions are answered properly, also sets this.successful. it also scrolls to questions not answered properly
    validateAnswers() {
      let values = Object.values(this.answers);
      for (let i = 0; i < values.length; i++) {
        if (values[i].hasSimilarSel) {
          this.popupactive = true;
          this.scrollto = values[i].Question;
          this.alert.title = "Info";
          this.alert.message = `Question ${values[i].Question} has selections on the same column`;
          // this.$router.push({
          //   name: "QuizOne",
          //   hash: `#question${values[i].Question}`,
          // });
          // alert(
          //   `Question ${values[i].Question} has selections on the same column`
          // );
          this.successful = false;
          break;
        } else if (values[i].hasEmptyValue) {
          this.popupactive = true;
          this.scrollto = values[i].Question;
          this.alert.title = "Info";
          this.alert.message = `Question ${values[i].Question} has some options unanswered`;
          // this.$router.push({
          //   name: "QuizOne",
          //   hash: `#question${values[i].Question}`,
          // });
          // alert(`Question ${values[i].Question} has some options unanswered`);
          this.successful = false;
          break;
        } else {
          this.successful = true;
        }
      }
    },
  },

  // this function makes user confirm before leaving page
  beforeRouteLeave() {
    if (!(Object.keys(this.answers).length == 25 && this.successful)) {
      const answer = window.confirm(
        "Do you really want to leave? Changes you made may not be saved."
      );
      if (!answer) return false;
    }
  },
  created() {
    this.startTimer();

    // this function makes sure the user comfirms refreshing page or closing the tab.
    window.addEventListener("beforeunload", function (event) {
      event.returnValue = "Changes you made may not be saved.";
    });
  },
  mounted() {
    this.readJsonFile();
  },
};
</script>