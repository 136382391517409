<template>
  <div class="question my-12 md:flex justify-between gap-2">
    <div class="mb-3 md:w-1/3">
      <p class="font-semibold text-md pb-5">Question {{ number }}</p>
      <p class="md:text-base" v-html="label"></p>
      <p
        class="
          text-danger
          transition
          animate-pulse
          md:text-md md:pt-4
          pt-2
          text-base
          font-black
        "
        v-show="hasSimilarSel"
      >
        <font-awesome-icon icon="info-circle" class="text-base md:pb-1" />
        Cannot have selections on the same column
      </p>
      <!-- {{ answers }} -->
    </div>

    <div class="md:w-2/3">
      <div class="lg:block hidden">
        <div class="lg:flex px-4">
          <div class="lg:w-4/6"></div>
          <div class="flex justify-around my-2 lg:w-2/6">
            <button content="Always True of me" v-tippy>1</button>
            <button content="Mostly True of me" v-tippy>2</button>
            <button content="Sometimes True of me" v-tippy>3</button>
            <button content="Rarely True of me" v-tippy>4</button>
          </div>
        </div>
      </div>
      <div
        class="px-1 border-transparent border-2"
        :class="{ 'has-similar-values': hasSimilarSel }"
      >
        <div v-for="answer in answers" :key="answer.id">
          <answer-component
            :id="answer.id"
            :number="number"
            :option="answer.options"
            @values="iniValues"
            @empty="iniEmpty"
            :valueObject="valueObject"
            >{{ answer.options }}. {{ answer.label }}</answer-component
          >
        </div>
      </div>
      <!-- <answer-component>A. this</answer-component>
      <answer-component>B. that</answer-component>
      <answer-component>C. they</answer-component>
      <answer-component>D. them</answer-component> -->
    </div>
  </div>
</template>

<script>
import AnswerComponent from "./AnswerComponent.vue";

export default {
  components: { AnswerComponent },
  name: "QuestionComponent",
  props: {
    label: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      valueObject: {},
      hasSimilarSel: false,
      hasEmptyValue: false,
      answerSet: {},
    };
  },
  methods: {
    iniValues(value, option) {
      if (option == "A") {
        this.value1 = value;
        this.valueObject.A = this.value1;
      } else if (option == "B") {
        this.value2 = value;
        this.valueObject.B = this.value2;
      } else if (option == "C") {
        this.value3 = value;
        this.valueObject.C = this.value3;
      } else if (option == "D") {
        this.value4 = value;
        this.valueObject.D = this.value4;
      }
      this.iniValuesArray();
      this.iniEmptyEntry();
      this.iniSelectionObject();
      console.log(this.answerSet);
      this.$emit("userSelection", this.answerSet, this.number);
    },

    //function to check if selections have similar values
    iniValuesArray() {
      let valueArray = Object.values(this.valueObject);
      // console.log(valueArray);
      this.hasSimilarSel =
        new Set(
          valueArray.map((value) => {
            return parseInt(value);
          })
        ).size !== valueArray.length;
    },

    //fucntion to check if selection have unanswered values
    iniEmptyEntry() {
      if (!this.value1 || !this.value2 || !this.value3 || !this.value4) {
        this.hasEmptyValue = true;
      } else {
        this.hasEmptyValue = false;
      }
    },

    //function that set user selections finally
    iniSelectionObject() {
      this.answerSet.Question = this.number;
      this.answerSet.Selection = this.valueObject;
      this.answerSet.hasSimilarSel = this.hasSimilarSel;
      this.answerSet.hasEmptyValue = this.hasEmptyValue;
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  box-shadow: none !important;
}

.has-similar-values {
  border: 2px solid #ff0000;
  border-radius: 8px;
  padding-inline: 4px;
}
</style>